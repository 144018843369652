<div mat-dialog-title class="d-title">
    {{newUser? "Sign Up" : "Log In"}}
    <div class="flex-grow"></div>
    <button mat-icon-button (click)="closeDialog()" color="warn"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content class="col-12">
    <div class="col-12" *ngIf="newUser">
        <form [formGroup]="registerForm">
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="Full Name *" formControlName="fullName" cdkFocusInitial>
                    <mat-icon class="input-error" color="warn" *ngIf="registerForm.get('fullName').errors?.required" matTooltip="Required">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="Company Name *" formControlName="companyName">
                    <mat-icon class="input-error" color="warn" *ngIf="registerForm.get('companyName').errors?.required" matTooltip="Required">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="Business Email / Work Email *" formControlName="email">
                    <mat-icon class="input-error" color="warn" *ngIf="registerForm.get('email').errors" [matTooltip]="getEmailError(registerForm.get('email'))">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="Password *" type="password" formControlName="password">
                    <mat-icon class="input-error" color="warn" *ngIf="registerForm.get('password').errors?.required" matTooltip="Required">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper register-as">
                    <mat-select class="input" [formControl]="loginOrRegisterAs" placeholder="Register as *">
                        <mat-option value="brand">Product Development</mat-option>
                        <mat-option value="marketplace"> Ingredient Providers</mat-option>
                    </mat-select>
                    <mat-icon class="input-error" color="warn" *ngIf="loginOrRegisterAs.errors?.required" matTooltip="Required">error</mat-icon>
                </div>
            </div>
            <div class="checkbox-wrapper">
                <mat-checkbox class="col-12 checkbox" color="primary" formControlName="subscribeToNewsletter" labelPosition="after">Do you wish to receive our E-newsletter and product safety updates?</mat-checkbox>
            </div>

            <div class="col-12 submit-wrapper">
                <button type="submit" class="p-submit" [disabled]="registerForm.invalid || loginOrRegisterAs.invalid || disableButton" (click)="register()">Login to your account <mat-spinner class="mx-2" diameter="24" *ngIf="disableButton"></mat-spinner></button>
            </div>
        </form>
        <div class="col-12 my-4 tnc-pp">
            By signing up, you agree to our 
                <span class="d-link" (click)="openTNC()">Terms & Conditions</span> and 
                <span class="d-link" (click)="openPrivacyPolicy()">Privacy Policy</span>.
        </div>
        <div class="col-12 my-4 registered">
            Already registered? <span class="d-link" (click)="openLogin()">Log in</span>
        </div>
    </div>

    <div class="col-12" *ngIf="!newUser">
        <form [formGroup]="loginForm">
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="Business Email / Work Email *" formControlName="email" cdkFocusInitial>
                    <mat-icon class="input-error" color="warn" *ngIf="loginForm.get('email').errors" [matTooltip]="getEmailError(loginForm.get('email'))">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="Password *" type="password" formControlName="password">
                    <mat-icon class="input-error" color="warn" *ngIf="loginForm.get('password').errors?.required" matTooltip="Required">error</mat-icon>
                </div>
            </div>
            <div class="input-field register-as-field">
                <div class="input-wrapper register-as">
                    <mat-select class="input" [formControl]="loginOrRegisterAs" placeholder="Login as">
                        <mat-option value="brand">Product Development</mat-option>
                        <mat-option value="marketplace"> Ingredient Providers</mat-option>
                    </mat-select>
                    <mat-icon class="input-error" color="warn" *ngIf="loginOrRegisterAs.errors?.required" matTooltip="Required">error</mat-icon>
                </div>
            </div>
            <div class="col-12 submit-wrapper">
                <button type="submit" class="p-submit" [disabled]="loginForm.invalid || loginOrRegisterAs.invalid || disableButton" (click)="login()">Login to your account <mat-spinner class="mx-2" diameter="24" *ngIf="disableButton"></mat-spinner></button>
            </div>
        </form>
        <div class="col-12 my-4 forgot-password">
            <span class="d-link" (click)="openForgotPassword()">Forgot Password?</span>
        </div>
        <div class="col-12 my-4 registered">
            Not registered? <span class="d-link" (click)="openRegister()">Sign Up</span>
        </div>
    </div>

</mat-dialog-content>