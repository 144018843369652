<div mat-dialog-content class="row" style="margin: 16px;">
    <h1 class="col-12" style="text-align: center; font-size: xxx-large; font-weight: bolder; line-height: 1;">PRIVACY POLICY</h1>
    
    <p class="col-12" style="margin: 8px 16px;">
        Productdev Edge Private Limited has created this privacy statement to demonstrate our company’s commitment to privacy. We understand you trust us with your personal information hence this site has security measures in place to protect the loss, misuse, alteration of information provided. Productdev Edge Private Limited is committed to providing privacy to our visitors. This policy is to bring before you the information that we collect, why we collect it and what we do with it; further ensuring that Productdev Edge Private Limited is compliant with the General Data Protection Regulation (GDPR) standards.
    </p>
    <p class="col-12" style="margin: 8px 16px;">
        This privacy policy is applicable to the below-mentioned domains:
    </p>
    <p class="col-12" style="margin: 8px 16px;">
        <a href="www.productdevedge.com">www.productdevedge.com</a> which provides a resource platform for cosmetic research and development under the brand “MAYA”.
    </p>
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">Personal Information</h4>
    
    <p class="col-12" style="margin: 8px 16px;">
        You can visit Productdev Edge Private Limited on the Internet without revealing any information about yourself.The website collects only the information which is volunteered by the site such as feedback and the e-mail addresses of those who communicate with us via e-mail. To give relevant content based on your preferences, we may need to ask your personal information:
    </p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">Full name. </li>
        <li class="col-12">Business / Work email </li>
        <li class="col-12">Alternate email </li>
        <li class="col-12">Telephone number</li>
        <li class="col-12">Country</li>
        <li class="col-12">State pin code</li>
        <li class="col-12">Digital identification viz. Internet Service Provider (ISP), Internet Protocol (IP) address, browser type, OS, device details</li>
        <li class="col-12">Metadata viz. location, cookies, time spent on pages, the information being surfed, access timing details etc.</li>
    </ul>
    <p class="col-12" style="margin: 8px 16px;">In case you do not provide your personal information, we may not be able to serve your request. Please be rest assured that Productdev Edge Private Limited does not utilize personal information to take automatic decisions.</p>
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">Disclosure of Personal Information</h4>
    <p class="col-12" style="margin: 8px 8px;">
        Productdev Edge Private Limited does not share your personal information with third parties for marketing purposes without seeking your prior permission. We will seek your consent prior to using or sharing your personal information for any purpose beyond the requirement for which it was originally collected.
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        We may share your personal information within the organization for data processing, storage, or to provide a requested service or transaction, after ensuring that such entities are contractually bound by data privacy obligations.
    </p>
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">Specific Categories of Personal Information</h4>
    <p class="col-12" style="margin: 8px 16px;">Productdev Edge Private Limited does not ask for the below listed delicate information unless needed by the law such as in the event of a court case.</p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">Racial information</li> 
        <li class="col-12">Political / Religious / Philosophical views</li> 
        <li class="col-12">Genetic or biometric information</li> 
        <li class="col-12">Health / Mortality / Sex-related information</li> 
    </ul>
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">Data Security, Retention, and Usage of Cookies</h4>
    <p class="col-12" style="margin: 8px 8px;">
        Productdev Edge Private Limited follows required appropriate security, administrative measures and processes that are a must to take care and guard your personal information. Productdev Edge Private Limited may retain your personal information if there is a business requirement, or if otherwise required under applicable laws.
    </p>
    
    <p class="col-12" style="margin: 8px 8px;">
        If you register yourself with any of our units online, a technology namely cookies may be used to provide you with information. A cookie is a small data element which a website can send to your browser, which will be stored on your hard drive so that it can be recognized when you return. Registering online with any of our services shows your assertive consent to receive such cookies.
    </p>

    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">Third Party sites</h4>
    <p class="col-12" style="margin: 8px 8px;">
        Regarding the links to other sites, Productdev Edge Private Limited is not responsible for the privacy practices or the content of such websites. This Privacy/Security statement does not apply to any third-party websites that are linked from this website. Even third-party logos, brands & names used on this website are the property of their respective owners. Productdev Edge Private Limited will not share your personal information with third parties for marketing purposes, without your prior consent.
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        We may provide links to third-party websites and services. However, we are not responsible for the privacy statements, practices or the contents of such third-party websites.
    </p>

    <h5 class="col-12" style="margin: 4px 8px; font-weight: bold;">Emails / We Newsletters</h5>
    <p class="col-12" style="margin: 8px 8px;">
        As a normal routine, we may utilize your email address to share the latest news/happenings at our end, with the help of our secure email marketing service provider. use the third-party data processor – Activetrail and your email information are also stored with us in our backend database as well as our CRM system.
    </p>

    <h5 class="col-12" style="margin: 4px 8px; font-weight: bold;">Marketing & Advertisement</h5>
    <p class="col-12" style="margin: 8px 8px;">
        The information you make available to us by visiting  our site, content or avail our services; We may display personalized/targeted online advertisements through Google owned and operated sites/associated sites.
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        We do not share any of your personal information with the third-party sites / advertising agencies that are responsible for showing targeted Ads. Yet, these third-parties and advertisers may understand that the users who interact with such Ads are part of that group for whom Ads are targeted. Also, some of these parties may give us information like demographic info or sites where you have been shown ads from various sources; that we may utilize to offer you enhanced / relevant advertising.
    </p>

    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">Data Protection Officer</h4>
    <p class="col-12" style="margin: 8px 8px;">
        Respecting the significance of data protection, Productdev Edge Private Limited has appointed a responsible data protection officer at the Corporate Office in Bangalore  who is well versed with the intricacies of organization’s internal data privacy policies and practices. You can contact the official for any query regarding the same. Following are the contact details of Productdev Edge Private Limited INDIA’s data protection officer:
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        Address: 413, DSR Woodwinds, Sarjapur Road, Doddakanehalli, Bangalore 560035.

        Email ID:  info&#64;productdevedge.com
   
        Contact no. : 8884966113

    </p>

    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">User Privileges and Access</h4>
    <p class="col-12" style="margin: 8px 8px;">
        Productdev Edge Private Limited is committed to give information (as listed below), requested by the user, after complete verification and within 30 days from the date of request. Only if it is needed by the law, it can be given earlier with an official email to info&#64;productdevedge.com . If it cannot be given earlier, we will provide the timeframe when it will be available. The info that can be asked by users is as below:
    </p>
    <p class="col-12" style="margin: 8px 8px; font-weight: bold;">What personal information related to the user that is under process?
    </p>
    <p class="col-12" style="margin: 8px 8px; font-weight: bold;">
        Who possesses the access rights to this useful information?
    </p>
    <p class="col-12" style="margin: 8px 8px; font-weight: bold;">
        Authentication / Rectification of personal information
    </p>
    <p class="col-12" style="margin: 8px 8px; font-weight: bold;">
        Deletion of personal information unless required by law such as in event of the court case
    </p>
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">
        Third-Party Data Processors</h4>
    <p class="col-12" style="margin: 8px 8px;"> 
        We have been utilizing several authentic third-party data processors for a variety of work. These third-parties are highly secure and comply with the rules and regulations set in the EU GDPR regulation act 2018.
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        Google (Privacy Policy)
    </p>
    <p  class="col-12" style="margin: 8px 8px;">
        cloud.google.com (<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>)
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        Office365 (<a href="https://www.mailerlite.com/privacy-policy">Security and Compliance</a>)
    </p>

    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">
        Data Breach
    </h4>
    <p class="col-12" style="margin: 8px 8px;">
        In case of any data breach of personal information/website’s database / third-party data, processors is apparent, we will, within 72 hours, inform to all relevant authorities about the same.
    </p>
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">
        Policy for Children
    </h4>
    <p class="col-12" style="margin: 8px 8px;">
        We ensure that we do not collect personal information of children under 16. If knowingly or unknowingly, some child under 16 has given us personal information, we will connect with Parent for consent or delete that information. If you want to inform us about such a happening, please write to us at 
        <a href="mailto:info@productdevedge.com">info&#64;productdevedge.com</a>.
    </p>
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">
        Updating this Privacy Policy
    </h4>
    <p class="col-12" style="margin: 8px 8px;">
        Productdev Edge Private Limited reserves the right to change, modify, or update this statement at any time. In case of any change, it will be marked on the top of the privacy policy page with a revised last updated date. In case of material changes, we will inform you through email or a notice on the home page of our website. We would appreciate if you have a look at our privacy policy at <a href="https://www.productdevedge.com">www.productdevedge.com</a> and keep updated with our practices.
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        If you have any questions about this privacy statement, the practices of this site, or your dealings with this website, you can contact <a href="mailto:info@productdevedge.com">info&#64;productdevedge.com</a>. All other trademarks and logos belong to their respective owners. All rights reserved.
        
    </p>
</div>
<mat-dialog-actions *ngIf="isDialogMode">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>