export const productCategoriesDefault: any = [
  {
    "productCategory": "Skin products",
    "productTypes": [
      {
        "productType": "Skin care products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Face care products other than face mask",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Face masks",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Eye contour products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Lip care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": true,
            "articleCategory": "Lotion",
            "claims": [
              "aha",
              "antiagiengwrinkle",
              "aromatheraphy",
              "balancing",
              "bodylotion",
              "clarifying",
              "moisturiser",
              "sunscreen",
              "toning"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Hand care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Foot care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Body care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "External intimate care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Chemical exfoliation products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Mechanical exfoliation products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Skin powder/Talc",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Skin Powder for infants",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other skin care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Baby care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Skin cleansing products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Soap products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Bath / shower products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Make-up remover products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "External Intimate hygiene products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Toilet Soap/Transparent Toilet Soap",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Liquid Toilet Soap",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Baby Toilet Soap",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other skin cleansing products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Baby cleansing products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Body hair removal",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Chemical depilatories",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Physical epilation products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other body hair removal products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Bleach for body hair products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Bleach for body hair",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Correction of body odour and/or perspiration",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Products with antiperspirant activity",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Products without antiperspirant activity",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Shaving and pre- / aftershaving products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Shaving products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Pre- / after-shaving products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other shaving and pre- / aftershaving products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Make-up products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Foundation",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Concealer",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other face make-up products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Mascara",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Eye shadow",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Eye pencil",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Eye liner",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other eye make-up products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Lip stick",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Lipstick sealer",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other lip make-up products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Body or face paint , including carnival make-up",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other make-up products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Perfumes",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Hydroalcoholic perfumes",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Non hydroalcoholic perfumes",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Perfume/Parfum/Cologne/Eau de toilette/Fragrance/Fragrance cream/Fine fragrance",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Sun and self-tanning products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Before and after sun products ",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Sun protection products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Self-tanning products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other sun and self-tanning products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Other skin products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other skin products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Other skin products Test",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other skin products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "test",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "sample1",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      }
    ],
    "enabled": true,
    "ai": false
  },
  {
    "productCategory": "Hair and scalp products",
    "productTypes": [
      {
        "productType": "Hair and scalp care and cleansing products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Hair conditioner/ hair lotion",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Scalp and hair roots care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Anti hairloss products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other hair and scalp care and cleansing products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Antidandruff products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Hair oil",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Shampoo, Soap-based",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Shampoo, Synthetic-Detergent based",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Hair cream",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Hair colouring/dye products/Eyebrows/Eyelashes",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Oxidative hair colour products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Non-oxidative hair colour products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Hair bleaching and dye remover products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Eyebrows dye and Eyelashes dye",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other hair colouring products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Hair styling products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Products for temporary hair styling",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Permanent wave products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Hair relaxer / straightener products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Pomades and Brilliantines",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other hair styling products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Other hair and scalp products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Hair sun protection products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other hair and scalp products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      }
    ],
    "enabled": true,
    "ai": false
  },
  {
    "productCategory": "Nail and cuticle products",
    "productTypes": [
      {
        "productType": "Nail varnish and remover products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Nail varnish / Nail make-up/Nail Polish (Nail Enamel)",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Nail varnish remover",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Nail varnish thinner",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Nail bleach",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other nail varnish and remover products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Nail care / nail hardener products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Nail care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Nail hardener",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other nail care / nail hardener products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Nail glue remover products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Nail glue remover",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Other nail and cuticle products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Cuticle remover / softener",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Nail sculpting products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other nail and cuticle products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      }
    ],
    "enabled": true,
    "ai": false
  },
  {
    "productCategory": "Oral hygiene products",
    "productTypes": [
      {
        "productType": "Tooth care products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Toothpaste",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Tooth cleansing powder / salt",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other tooth care products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Mouth wash / breath spray",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Mouth wash",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Breath spray",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          },
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other mouth wash / breath spray products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Tooth whiteners",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Tooth whiteners",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      },
      {
        "productType": "Other oral hygiene products",
        "articleCategories": [
          {
            "enabled": true,
            "ai": false,
            "articleCategory": "Other oral hygiene products",
            "claims": [
              "Hydration",
              "Softening",
              "Smoothening"
            ]
          }
        ],
        "enabled": true,
        "ai": false
      }
    ],
    "enabled": true,
    "ai": false
  }
]

export const mimeTypes: any = {
  "pdf": "application/pdf",
  "jpg": "image/jpeg",
  "jpeg": "image/jpeg",
  "png": "image/png",
  "gif": "image/gif",
  "webp": "image/webp",
  "avi": "video/x-msvideo",
  "mp4": "video/mp4",
  "mpeg": "video/mpeg",
  "webm": "video/webm"
}

export const defaultSearchFields: any = ["chemical_name", "cas_nos.cas_no", "inci", "inn", "iupac_name", "other_names", "common_name"];
  
export const countryList: any = [
	{ "code": "AF", "code3": "AFG", "name": "Afghanistan", "number": "004" },
	{ "code": "AL", "code3": "ALB", "name": "Albania", "number": "008" },
	{ "code": "DZ", "code3": "DZA", "name": "Algeria", "number": "012", "zipReg": "\d{5}" },
	{ "code": "AS", "code3": "ASM", "name": "American Samoa", "number": "016", "zipReg": "96799" },
	{ "code": "AD", "code3": "AND", "name": "Andorra", "number": "020", "zipReg": "AD\d{3}" },
	{ "code": "AO", "code3": "AGO", "name": "Angola", "number": "024" },
	{ "code": "AI", "code3": "AIA", "name": "Anguilla", "number": "660" },
	{ "code": "AQ", "code3": "ATA", "name": "Antarctica", "number": "010" },
	{ "code": "AG", "code3": "ATG", "name": "Antigua and Barbuda", "number": "028" },
	{ "code": "AR", "code3": "ARG", "name": "Argentina", "number": "032", "zipReg": "([A-HJ-NP-Z])?\d{4}([A-Z]{3})?" },
	{ "code": "AM", "code3": "ARM", "name": "Armenia", "number": "051", "zipReg": "(37)?\d{4}" },
	{ "code": "AW", "code3": "ABW", "name": "Aruba", "number": "533" },
	{ "code": "AU", "code3": "AUS", "name": "Australia", "number": "036" },
	{ "code": "AT", "code3": "AUT", "name": "Austria", "number": "040" },
	{ "code": "AZ", "code3": "AZE", "name": "Azerbaijan", "number": "031" },
	{ "code": "BS", "code3": "BHS", "name": "Bahamas (the)", "number": "044" },
	{ "code": "BH", "code3": "BHR", "name": "Bahrain", "number": "048" },
	{ "code": "BD", "code3": "BGD", "name": "Bangladesh", "number": "050" },
	{ "code": "BB", "code3": "BRB", "name": "Barbados", "number": "052" },
	{ "code": "BY", "code3": "BLR", "name": "Belarus", "number": "112" },
	{ "code": "BE", "code3": "BEL", "name": "Belgium", "number": "056" },
	{ "code": "BZ", "code3": "BLZ", "name": "Belize", "number": "084" },
	{ "code": "BJ", "code3": "BEN", "name": "Benin", "number": "204" },
	{ "code": "BM", "code3": "BMU", "name": "Bermuda", "number": "060" },
	{ "code": "BT", "code3": "BTN", "name": "Bhutan", "number": "064" },
	{ "code": "BO", "code3": "BOL", "name": "Bolivia (Plurinational State of)", "number": "068" },
	{ "code": "BQ", "code3": "BES", "name": "Bonaire, Sint Eustatius and Saba", "number": "535" },
	{ "code": "BA", "code3": "BIH", "name": "Bosnia and Herzegovina", "number": "070" },
	{ "code": "BW", "code3": "BWA", "name": "Botswana", "number": "072" },
	{ "code": "BV", "code3": "BVT", "name": "Bouvet Island", "number": "074" },
	{ "code": "BR", "code3": "BRA", "name": "Brazil", "number": "076" },
	{ "code": "IO", "code3": "IOT", "name": "British Indian Ocean Territory (the)", "number": "086" },
	{ "code": "BN", "code3": "BRN", "name": "Brunei Darussalam", "number": "096" },
	{ "code": "BG", "code3": "BGR", "name": "Bulgaria", "number": "100" },
	{ "code": "BF", "code3": "BFA", "name": "Burkina Faso", "number": "854" },
	{ "code": "BI", "code3": "BDI", "name": "Burundi", "number": "108" },
	{ "code": "CV", "code3": "CPV", "name": "Cabo Verde", "number": "132" },
	{ "code": "KH", "code3": "KHM", "name": "Cambodia", "number": "116" },
	{ "code": "CM", "code3": "CMR", "name": "Cameroon", "number": "120" },
	{ "code": "CA", "code3": "CAN", "name": "Canada", "number": "124" },
	{ "code": "KY", "code3": "CYM", "name": "Cayman Islands (the)", "number": "136" },
	{ "code": "CF", "code3": "CAF", "name": "Central African Republic (the)", "number": "140" },
	{ "code": "TD", "code3": "TCD", "name": "Chad", "number": "148" },
	{ "code": "CL", "code3": "CHL", "name": "Chile", "number": "152" },
	{ "code": "CN", "code3": "CHN", "name": "China", "number": "156" },
	{ "code": "CX", "code3": "CXR", "name": "Christmas Island", "number": "162" },
	{ "code": "CC", "code3": "CCK", "name": "Cocos (Keeling) Islands (the)", "number": "166" },
	{ "code": "CO", "code3": "COL", "name": "Colombia", "number": "170" },
	{ "code": "KM", "code3": "COM", "name": "Comoros (the)", "number": "174" },
	{ "code": "CD", "code3": "COD", "name": "Congo (the Democratic Republic of the)", "number": "180" },
	{ "code": "CG", "code3": "COG", "name": "Congo (the)", "number": "178" },
	{ "code": "CK", "code3": "COK", "name": "Cook Islands (the)", "number": "184" },
	{ "code": "CR", "code3": "CRI", "name": "Costa Rica", "number": "188" },
	{ "code": "HR", "code3": "HRV", "name": "Croatia", "number": "191" },
	{ "code": "CU", "code3": "CUB", "name": "Cuba", "number": "192" },
	{ "code": "CW", "code3": "CUW", "name": "Curaçao", "number": "531" },
	{ "code": "CY", "code3": "CYP", "name": "Cyprus", "number": "196" },
	{ "code": "CZ", "code3": "CZE", "name": "Czechia", "number": "203" },
	{ "code": "CI", "code3": "CIV", "name": "Côte d'Ivoire", "number": "384" },
	{ "code": "DK", "code3": "DNK", "name": "Denmark", "number": "208" },
	{ "code": "DJ", "code3": "DJI", "name": "Djibouti", "number": "262" },
	{ "code": "DM", "code3": "DMA", "name": "Dominica", "number": "212" },
	{ "code": "DO", "code3": "DOM", "name": "Dominican Republic (the)", "number": "214" },
	{ "code": "EC", "code3": "ECU", "name": "Ecuador", "number": "218" },
	{ "code": "EG", "code3": "EGY", "name": "Egypt", "number": "818" },
	{ "code": "SV", "code3": "SLV", "name": "El Salvador", "number": "222" },
	{ "code": "GQ", "code3": "GNQ", "name": "Equatorial Guinea", "number": "226" },
	{ "code": "ER", "code3": "ERI", "name": "Eritrea", "number": "232" },
	{ "code": "EE", "code3": "EST", "name": "Estonia", "number": "233" },
	{ "code": "SZ", "code3": "SWZ", "name": "Eswatini", "number": "748" },
	{ "code": "ET", "code3": "ETH", "name": "Ethiopia", "number": "231" },
	{ "code": "FK", "code3": "FLK", "name": "Falkland Islands (the) [Malvinas]", "number": "238" },
	{ "code": "FO", "code3": "FRO", "name": "Faroe Islands (the)", "number": "234" },
	{ "code": "FJ", "code3": "FJI", "name": "Fiji", "number": "242" },
	{ "code": "FI", "code3": "FIN", "name": "Finland", "number": "246" },
	{ "code": "FR", "code3": "FRA", "name": "France", "number": "250" },
	{ "code": "GF", "code3": "GUF", "name": "French Guiana", "number": "254" },
	{ "code": "PF", "code3": "PYF", "name": "French Polynesia", "number": "258" },
	{ "code": "TF", "code3": "ATF", "name": "French Southern Territories (the)", "number": "260" },
	{ "code": "GA", "code3": "GAB", "name": "Gabon", "number": "266" },
	{ "code": "GM", "code3": "GMB", "name": "Gambia (the)", "number": "270" },
	{ "code": "GE", "code3": "GEO", "name": "Georgia", "number": "268" },
	{ "code": "DE", "code3": "DEU", "name": "Germany", "number": "276" },
	{ "code": "GH", "code3": "GHA", "name": "Ghana", "number": "288" },
	{ "code": "GI", "code3": "GIB", "name": "Gibraltar", "number": "292" },
	{ "code": "GR", "code3": "GRC", "name": "Greece", "number": "300" },
	{ "code": "GL", "code3": "GRL", "name": "Greenland", "number": "304" },
	{ "code": "GD", "code3": "GRD", "name": "Grenada", "number": "308" },
	{ "code": "GP", "code3": "GLP", "name": "Guadeloupe", "number": "312" },
	{ "code": "GU", "code3": "GUM", "name": "Guam", "number": "316" },
	{ "code": "GT", "code3": "GTM", "name": "Guatemala", "number": "320" },
	{ "code": "GG", "code3": "GGY", "name": "Guernsey", "number": "831" },
	{ "code": "GN", "code3": "GIN", "name": "Guinea", "number": "324" },
	{ "code": "GW", "code3": "GNB", "name": "Guinea-Bissau", "number": "624" },
	{ "code": "GY", "code3": "GUY", "name": "Guyana", "number": "328" },
	{ "code": "HT", "code3": "HTI", "name": "Haiti", "number": "332" },
	{ "code": "HM", "code3": "HMD", "name": "Heard Island and McDonald Islands", "number": "334" },
	{ "code": "VA", "code3": "VAT", "name": "Holy See (the)", "number": "336" },
	{ "code": "HN", "code3": "HND", "name": "Honduras", "number": "340" },
	{ "code": "HK", "code3": "HKG", "name": "Hong Kong", "number": "344" },
	{ "code": "HU", "code3": "HUN", "name": "Hungary", "number": "348" },
	{ "code": "IS", "code3": "ISL", "name": "Iceland", "number": "352" },
	{ "code": "IN", "code3": "IND", "name": "India", "number": "356" },
	{ "code": "ID", "code3": "IDN", "name": "Indonesia", "number": "360" },
	{ "code": "IR", "code3": "IRN", "name": "Iran (Islamic Republic of)", "number": "364" },
	{ "code": "IQ", "code3": "IRQ", "name": "Iraq", "number": "368" },
	{ "code": "IE", "code3": "IRL", "name": "Ireland", "number": "372" },
	{ "code": "IM", "code3": "IMN", "name": "Isle of Man", "number": "833" },
	{ "code": "IL", "code3": "ISR", "name": "Israel", "number": "376" },
	{ "code": "IT", "code3": "ITA", "name": "Italy", "number": "380" },
	{ "code": "JM", "code3": "JAM", "name": "Jamaica", "number": "388" },
	{ "code": "JP", "code3": "JPN", "name": "Japan", "number": "392" },
	{ "code": "JE", "code3": "JEY", "name": "Jersey", "number": "832" },
	{ "code": "JO", "code3": "JOR", "name": "Jordan", "number": "400" },
	{ "code": "KZ", "code3": "KAZ", "name": "Kazakhstan", "number": "398" },
	{ "code": "KE", "code3": "KEN", "name": "Kenya", "number": "404" },
	{ "code": "KI", "code3": "KIR", "name": "Kiribati", "number": "296" },
	{ "code": "KP", "code3": "PRK", "name": "Korea (the Democratic People's Republic of)", "number": "408" },
	{ "code": "KR", "code3": "KOR", "name": "Korea (the Republic of)", "number": "410" },
	{ "code": "KW", "code3": "KWT", "name": "Kuwait", "number": "414" },
	{ "code": "KG", "code3": "KGZ", "name": "Kyrgyzstan", "number": "417" },
	{ "code": "LA", "code3": "LAO", "name": "Lao People's Democratic Republic (the)", "number": "418" },
	{ "code": "LV", "code3": "LVA", "name": "Latvia", "number": "428" },
	{ "code": "LB", "code3": "LBN", "name": "Lebanon", "number": "422" },
	{ "code": "LS", "code3": "LSO", "name": "Lesotho", "number": "426" },
	{ "code": "LR", "code3": "LBR", "name": "Liberia", "number": "430" },
	{ "code": "LY", "code3": "LBY", "name": "Libya", "number": "434" },
	{ "code": "LI", "code3": "LIE", "name": "Liechtenstein", "number": "438" },
	{ "code": "LT", "code3": "LTU", "name": "Lithuania", "number": "440" },
	{ "code": "LU", "code3": "LUX", "name": "Luxembourg", "number": "442" },
	{ "code": "MO", "code3": "MAC", "name": "Macao", "number": "446" },
	{ "code": "MG", "code3": "MDG", "name": "Madagascar", "number": "450" },
	{ "code": "MW", "code3": "MWI", "name": "Malawi", "number": "454" },
	{ "code": "MY", "code3": "MYS", "name": "Malaysia", "number": "458" },
	{ "code": "MV", "code3": "MDV", "name": "Maldives", "number": "462" },
	{ "code": "ML", "code3": "MLI", "name": "Mali", "number": "466" },
	{ "code": "MT", "code3": "MLT", "name": "Malta", "number": "470" },
	{ "code": "MH", "code3": "MHL", "name": "Marshall Islands (the)", "number": "584" },
	{ "code": "MQ", "code3": "MTQ", "name": "Martinique", "number": "474" },
	{ "code": "MR", "code3": "MRT", "name": "Mauritania", "number": "478" },
	{ "code": "MU", "code3": "MUS", "name": "Mauritius", "number": "480" },
	{ "code": "YT", "code3": "MYT", "name": "Mayotte", "number": "175" },
	{ "code": "MX", "code3": "MEX", "name": "Mexico", "number": "484" },
	{ "code": "FM", "code3": "FSM", "name": "Micronesia (Federated States of)", "number": "583" },
	{ "code": "MD", "code3": "MDA", "name": "Moldova (the Republic of)", "number": "498" },
	{ "code": "MC", "code3": "MCO", "name": "Monaco", "number": "492" },
	{ "code": "MN", "code3": "MNG", "name": "Mongolia", "number": "496" },
	{ "code": "ME", "code3": "MNE", "name": "Montenegro", "number": "499" },
	{ "code": "MS", "code3": "MSR", "name": "Montserrat", "number": "500" },
	{ "code": "MA", "code3": "MAR", "name": "Morocco", "number": "504" },
	{ "code": "MZ", "code3": "MOZ", "name": "Mozambique", "number": "508" },
	{ "code": "MM", "code3": "MMR", "name": "Myanmar", "number": "104" },
	{ "code": "NA", "code3": "NAM", "name": "Namibia", "number": "516" },
	{ "code": "NR", "code3": "NRU", "name": "Nauru", "number": "520" },
	{ "code": "NP", "code3": "NPL", "name": "Nepal", "number": "524" },
	{ "code": "NL", "code3": "NLD", "name": "Netherlands (the)", "number": "528" },
	{ "code": "NC", "code3": "NCL", "name": "New Caledonia", "number": "540" },
	{ "code": "NZ", "code3": "NZL", "name": "New Zealand", "number": "554" },
	{ "code": "NI", "code3": "NIC", "name": "Nicaragua", "number": "558" },
	{ "code": "NE", "code3": "NER", "name": "Niger (the)", "number": "562" },
	{ "code": "NG", "code3": "NGA", "name": "Nigeria", "number": "566" },
	{ "code": "NU", "code3": "NIU", "name": "Niue", "number": "570" },
	{ "code": "NF", "code3": "NFK", "name": "Norfolk Island", "number": "574" },
	{ "code": "MP", "code3": "MNP", "name": "Northern Mariana Islands (the)", "number": "580" },
	{ "code": "NO", "code3": "NOR", "name": "Norway", "number": "578" },
	{ "code": "OM", "code3": "OMN", "name": "Oman", "number": "512" },
	{ "code": "PK", "code3": "PAK", "name": "Pakistan", "number": "586" },
	{ "code": "PW", "code3": "PLW", "name": "Palau", "number": "585" },
	{ "code": "PS", "code3": "PSE", "name": "Palestine, State of", "number": "275" },
	{ "code": "PA", "code3": "PAN", "name": "Panama", "number": "591" },
	{ "code": "PG", "code3": "PNG", "name": "Papua New Guinea", "number": "598" },
	{ "code": "PY", "code3": "PRY", "name": "Paraguay", "number": "600" },
	{ "code": "PE", "code3": "PER", "name": "Peru", "number": "604" },
	{ "code": "PH", "code3": "PHL", "name": "Philippines (the)", "number": "608" },
	{ "code": "PN", "code3": "PCN", "name": "Pitcairn", "number": "612" },
	{ "code": "PL", "code3": "POL", "name": "Poland", "number": "616" },
	{ "code": "PT", "code3": "PRT", "name": "Portugal", "number": "620" },
	{ "code": "PR", "code3": "PRI", "name": "Puerto Rico", "number": "630" },
	{ "code": "QA", "code3": "QAT", "name": "Qatar", "number": "634" },
	{ "code": "MK", "code3": "MKD", "name": "Republic of North Macedonia", "number": "807" },
	{ "code": "RO", "code3": "ROU", "name": "Romania", "number": "642" },
	{ "code": "RU", "code3": "RUS", "name": "Russian Federation (the)", "number": "643" },
	{ "code": "RW", "code3": "RWA", "name": "Rwanda", "number": "646" },
	{ "code": "RE", "code3": "REU", "name": "Réunion", "number": "638" },
	{ "code": "BL", "code3": "BLM", "name": "Saint Barthélemy", "number": "652" },
	{ "code": "SH", "code3": "SHN", "name": "Saint Helena, Ascension and Tristan da Cunha", "number": "654" },
	{ "code": "KN", "code3": "KNA", "name": "Saint Kitts and Nevis", "number": "659" },
	{ "code": "LC", "code3": "LCA", "name": "Saint Lucia", "number": "662" },
	{ "code": "MF", "code3": "MAF", "name": "Saint Martin (French part)", "number": "663" },
	{ "code": "PM", "code3": "SPM", "name": "Saint Pierre and Miquelon", "number": "666" },
	{ "code": "VC", "code3": "VCT", "name": "Saint Vincent and the Grenadines", "number": "670" },
	{ "code": "WS", "code3": "WSM", "name": "Samoa", "number": "882" },
	{ "code": "SM", "code3": "SMR", "name": "San Marino", "number": "674" },
	{ "code": "ST", "code3": "STP", "name": "Sao Tome and Principe", "number": "678" },
	{ "code": "SA", "code3": "SAU", "name": "Saudi Arabia", "number": "682" },
	{ "code": "SN", "code3": "SEN", "name": "Senegal", "number": "686" },
	{ "code": "RS", "code3": "SRB", "name": "Serbia", "number": "688" },
	{ "code": "SC", "code3": "SYC", "name": "Seychelles", "number": "690" },
	{ "code": "SL", "code3": "SLE", "name": "Sierra Leone", "number": "694" },
	{ "code": "SG", "code3": "SGP", "name": "Singapore", "number": "702" },
	{ "code": "SX", "code3": "SXM", "name": "Sint Maarten (Dutch part)", "number": "534" },
	{ "code": "SK", "code3": "SVK", "name": "Slovakia", "number": "703" },
	{ "code": "SI", "code3": "SVN", "name": "Slovenia", "number": "705" },
	{ "code": "SB", "code3": "SLB", "name": "Solomon Islands", "number": "090" },
	{ "code": "SO", "code3": "SOM", "name": "Somalia", "number": "706" },
	{ "code": "ZA", "code3": "ZAF", "name": "South Africa", "number": "710" },
	{ "code": "GS", "code3": "SGS", "name": "South Georgia and the South Sandwich Islands", "number": "239" },
	{ "code": "SS", "code3": "SSD", "name": "South Sudan", "number": "728" },
	{ "code": "ES", "code3": "ESP", "name": "Spain", "number": "724" },
	{ "code": "LK", "code3": "LKA", "name": "Sri Lanka", "number": "144" },
	{ "code": "SD", "code3": "SDN", "name": "Sudan (the)", "number": "729" },
	{ "code": "SR", "code3": "SUR", "name": "Suriname", "number": "740" },
	{ "code": "SJ", "code3": "SJM", "name": "Svalbard and Jan Mayen", "number": "744" },
	{ "code": "SE", "code3": "SWE", "name": "Sweden", "number": "752" },
	{ "code": "CH", "code3": "CHE", "name": "Switzerland", "number": "756" },
	{ "code": "SY", "code3": "SYR", "name": "Syrian Arab Republic", "number": "760" },
	{ "code": "TW", "code3": "TWN", "name": "Taiwan", "number": "158" },
	{ "code": "TJ", "code3": "TJK", "name": "Tajikistan", "number": "762" },
	{ "code": "TZ", "code3": "TZA", "name": "Tanzania, United Republic of", "number": "834" },
	{ "code": "TH", "code3": "THA", "name": "Thailand", "number": "764" },
	{ "code": "TL", "code3": "TLS", "name": "Timor-Leste", "number": "626" },
	{ "code": "TG", "code3": "TGO", "name": "Togo", "number": "768" },
	{ "code": "TK", "code3": "TKL", "name": "Tokelau", "number": "772" },
	{ "code": "TO", "code3": "TON", "name": "Tonga", "number": "776" },
	{ "code": "TT", "code3": "TTO", "name": "Trinidad and Tobago", "number": "780" },
	{ "code": "TN", "code3": "TUN", "name": "Tunisia", "number": "788" },
	{ "code": "TR", "code3": "TUR", "name": "Turkey", "number": "792" },
	{ "code": "TM", "code3": "TKM", "name": "Turkmenistan", "number": "795" },
	{ "code": "TC", "code3": "TCA", "name": "Turks and Caicos Islands (the)", "number": "796" },
	{ "code": "TV", "code3": "TUV", "name": "Tuvalu", "number": "798" },
	{ "code": "UG", "code3": "UGA", "name": "Uganda", "number": "800" },
	{ "code": "UA", "code3": "UKR", "name": "Ukraine", "number": "804" },
	{ "code": "AE", "code3": "ARE", "name": "United Arab Emirates (the)", "number": "784" },
	{ "code": "GB", "code3": "GBR", "name": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826" },
	{ "code": "UM", "code3": "UMI", "name": "United States Minor Outlying Islands (the)", "number": "581" },
	{ "code": "US", "code3": "USA", "name": "United States of America (the)", "number": "840" },
	{ "code": "UY", "code3": "URY", "name": "Uruguay", "number": "858" },
	{ "code": "UZ", "code3": "UZB", "name": "Uzbekistan", "number": "860" },
	{ "code": "VU", "code3": "VUT", "name": "Vanuatu", "number": "548" },
	{ "code": "VE", "code3": "VEN", "name": "Venezuela (Bolivarian Republic of)", "number": "862" },
	{ "code": "VN", "code3": "VNM", "name": "Viet Nam", "number": "704" },
	{ "code": "VG", "code3": "VGB", "name": "Virgin Islands (British)", "number": "092" },
	{ "code": "VI", "code3": "VIR", "name": "Virgin Islands (U.S.)", "number": "850" },
	{ "code": "WF", "code3": "WLF", "name": "Wallis and Futuna", "number": "876" },
	{ "code": "EH", "code3": "ESH", "name": "Western Sahara", "number": "732" },
	{ "code": "YE", "code3": "YEM", "name": "Yemen", "number": "887" },
	{ "code": "ZM", "code3": "ZMB", "name": "Zambia", "number": "894" },
	{ "code": "ZW", "code3": "ZWE", "name": "Zimbabwe", "number": "716" },
	{ "code": "AX", "code3": "ALA", "name": "Åland Islands", "number": "248" }
];


export const defaultMarketplaceFilters: any = {
  tradeNameQuery: "",
  status: ["Verified", "Verification Pending", "Verification Rejected"]
}

export const profileKeys: any = [
  { key: "_id", type: "string" },
  { key: "fullName", type: "string" },
  { key: "companyName", type: "string" },
  { key: "email", type: "string" },
  { key: "userType", type: "string" },
  { key: "subscribedToNewsletter", type: "string" },
  { key: "created", type: "Date" },
  { key: "setupComplete", type: "boolean" },
  { key: "phoneNo", type: "string" },
  { key: "designation", type: "string" },
  { key: "companyAddress", type: "string" },
  { key: "pincode", type: "string" },
  { key: "country", type: "string" },
  { key: "state", type: "string" },
  { key: "manufacturer", type: "string" },
  { key: "warehouseLocation", type: "string" },
  { key: "contactPerson", type: "string" },
  { key: "paidPlan", type: "boolean" },
  { key: "plan", type: "string" },
  { key: "ingredientsSearched", type: "number" },
  { key: "accountVerified", type: "boolean" },
  { key: "fileExists", type: "boolean" },
  { key: "fileName", type: "string" },
  { key: "feedbackSubmitted", type: "boolean" },
  { key: "totalLoginDurationInMins", type: "number" },
  { key: "totalLoginDuration", type: "number" },
  { key: "accountAllowed", type: "boolean" },
  { key: "extraAllowanceRequested", type: "boolean" },
  { key: "ingredientsLimit", type: "number" },
  { key: "productsLimit", type: "number" },
  { key: "productIngredientsLimit", type: "number" },
  { key: "productIngredientsSearched", type: "number" },
  { key: "productsGenerated", type: "number" },
  { key: "formulasGenerated", type: "number" },
  { key: "selectedPlan", type: "string" },
  { key: "dashboardEnabled", type: "boolean" },
  { key: "vlEnabled", type: "boolean" },
  { key: "planExpiry", type: "string" },
  { key: "planExpired", type: "boolean" },
  { key: "notifications", type: "array" },
  { key: "tourCompletion", type: "object" },
  { key: "planDetails", type: "object" },
  { key: "planCompletion", type: "object" },
  { key: "chemicalGroups", type: "array" },
  { key: "certificates", type: "array" },
  { key: "website", type: "string" },
  { key: "companyDetails", type: "string" },
  { key: "ingredientProfile", type: "string" },
  { key: "established", type: "number" },
  { key: "markettingFiles", type: "object" }
]

export const aiProductCategoriesDefault: any = [
  {
      "productCategory" : "Skin products",
      "productTypes" : [
          {
              "productType" : "Skin care products",
              "articleCategories" : [
                  {
                      "articleCategory" : "Lotion",
                      "defaultUseCategory": "leave_on_scoring",
                      "articleTextures": [
                          {
                              "articleTexture" : "Lotion",
                              "claims" : [
                                  "Body lotion",
                                  "Moisturiser",
                                  "Anti-ageing",
                                  "Aromatherapy",
                                  "Sunscreen"
                              ]
                          },
                          {
                              "articleTexture" : "Cream",
                              "claims" : [
                                  "Moisturiser",
                                  "Anti-ageing",
                                  "Aromatherapy",
                                  "Sunscreen"
                              ]
                          },
                          {
                              "articleTexture" : "Liquid",
                              "claims" : [
                                  "AHA",
                                  "Balancing",
                                  "Clarifying",
                                  "Toning"
                              ]
                          },
                          {
                              "articleTexture" : "Gel",
                              "claims" : [
                                  "Sunscreen"
                              ]
                          },
                          {
                              "articleTexture" : "Spray",
                              "claims" : [
                                  "Sunscreen"                                        
                              ]
                          },
                          {
                              "articleTexture" : "Stick",
                              "claims" : [
                                  "Aromatherapy",
                                  "Sunscreen"
                              ]
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "productCategory" : "Hair and scalp products",
      "productTypes" : [
          {
              "productType" : "Hair and scalp care and cleansing products",
              "articleCategories" : [
                  {
                      "articleCategory" : "Shampoo",
                      "defaultUseCategory": "rinse_off_scoring",
                      "articleTextures": [
                          {
                              "articleTexture" : "Gel",
                              "claims" : [
                                  "Hair cleansing",
                                  "Two-in-one conditioning",
                                  "Color protecting",
                                  "Bleached hair",
                                  "Moisturizing",
                                  "Damage repairing",
                                  "Anti-dandruff",
                                  "Oily scalp",
                                  "Hair growth",
                                  "Anti hair fall"
                              ]
                          },
                          {
                              "articleTexture" : "Solid",
                              "claims" : [
                                  "Hair cleansing"
                              ]
                          },
                          {
                              "articleTexture" : "Powder",
                              "claims" : [
                                  "Dry skin",
                                  "Oily scalp",
                                  "Moisturizing"
                              ]
                          }
                      ]
                  },
                  {
                      "articleCategory" : "Conditioner",
                      "defaultUseCategory": "rinse_off_scoring",
                      "articleTextures": [
                          {
                              "articleTexture" : "Gel",
                              "claims" : [
                                  "Hair cleansing",
                                  "Color protecting",
                                  "Bleached hair",
                                  "Moisturizing",
                                  "Damage repairing",
                                  "Anti-dandruff",
                                  "Oily scalp",
                                  "Hair growth",
                                  "Anti hair fall"
                              ]
                          },
                          {
                              "articleTexture" : "Cream",
                              "claims": [
                                  "Hair cleansing",
                                  "Color protecting",
                                  "Bleached hair",
                                  "Moisturizing",
                                  "Damage repairing",
                                  "Anti-dandruff",
                                  "Oily scalp",
                                  "Hair growth",
                                  "Anti hair fall"
                              ]
                          }
                      ]
                  }
              ]
          }
      ]
  }
]

export const prohib_domains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.in",
  "yahoo.co.in",
  "live.com",
  "msn.com",
  "yahoo.co.uk",
  "rediffmail.com",
  "yandex.ru",
  "ymail.com",
  "outlook.com"
]

const lipCareProducts = [
  "Emollient derived from L-Glutamic amino acid and phytosterol",
  "colourless viscous liquid appearance",
  "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
  "Repairs Skin and hair damage",
  "Excellent emolliency - High water holding capacity",
  "Improves barrier function of damaged skin",
  "Gives conditioning effect to protect skin from daily stress",
  "Repairs Hair damage by dyeing/ Colouring treatments",
  "Gives conditioning effect to protect skin from daily stress",
  "Gives conditioning effect to protect hairs from daily cleansing stress",
  "Mild AHA (exfoliation & skin lightening)",
  "Polyphenol extracted from rice bran",
  "Skin Moisturization & Conditioning",
  "Sebum regulator -Skin lightening",
  "Prevent discoloration",
  "pH adjuster",
  "Strong Metal chelator",
  "Deodorant",
  "Anti-oxidant",
  "Highly biodegradable",
  "Highly effective Oil gelling agents",
  "Oil Gel stable at high temperature",
  "sensory feeling of oil gels same as oil",
  "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
  "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
  "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
  "Highly effective Oil gelling agents",
  "Oil Gel stable at high temperature",
  "sensory feeling of oil gels same as oil",
  "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
  "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
  "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
  "Good water-holding capability & glossiness oil paste",
  "Excellent hair conditioning ability - Non animal source of all raw material",
  "Pigment dispersant & binder",
  "Excellent ability for preventing sweating of lipsticks & foundations",
  "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
  "gelling agent for various oils",
  "Possible to prepare gels with thixotropic properties",
  "Stabilizer for W/O emulsion Solubility",
  "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
  "silicone gelling agent",
  "Available as thickener for various oils",
  "Possible to prepare soft gels with thixotropic properties",
  "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
]

export const availableProductClaims = {
  "eye contour products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water"
  ],
  "face care products other than face mask": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Anti ageing",
      "anti -acne",
      "repairing",
      "soothing for photoaged skin.",
      "Elasticity and firmness to skin",
      "Cell renewal",
      "Skin lifting",
      "rejuvenating",
      "Anti- aging",
      "For sensitive skin",
      "skin barrier",
      "reduces redness and erythema",
      "Soothes skin irritation.",
      "Anti-pigmentation",
      "Even tone",
      "Skin brightening",
      "Skin homogeneity",
      "Cellular repair",
      "anti aging",
      "DNA repair",
      "Boost radiance",
      "reduces wrinkles and improves firmness",
      "Boosts Skin radiance",
      "restores skin firmness",
      "reduces wrinkles in 28 days",
      "Cell renewal",
      "reduces skin imperfections",
      "boosts radiance",
      "Instant Hydrating",
      "Instant Plumping",
      "Skin firming",
      "Skin repairing",
      "Skin radiance",
      "Anti - inflammatory",
      "Skin repairing",
      "Scar reduction",
      "Sebo regulating",
      "Acne/oily skin care",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Skin Radiance",
      "Skin Lightening",
      "Skin Microcirculation",
      "minimises photo aging",
      "increase hair volume",
      "shiny hair"
  ],
  "lotion": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Anti ageing",
      "anti -acne",
      "repairing",
      "soothing for photoaged skin.",
      "Elasticity and firmness to skin",
      "Cell renewal",
      "Skin lifting",
      "rejuvenating",
      "Anti- aging",
      "For sensitive skin",
      "skin barrier",
      "reduces redness and erythema",
      "Soothes skin irritation.",
      "Anti-pigmentation",
      "Even tone",
      "Skin brightening",
      "Skin homogeneity",
      "Cellular repair",
      "anti aging",
      "DNA repair",
      "Boost radiance",
      "reduces wrinkles and improves firmness",
      "Boosts Skin radiance",
      "restores skin firmness",
      "reduces wrinkles in 28 days",
      "Cell renewal",
      "reduces skin imperfections",
      "boosts radiance",
      "Instant Hydrating",
      "Instant Plumping",
      "Skin firming",
      "Skin repairing",
      "Skin radiance",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "Skin Radiance",
      "Skin Lightening",
      "Skin Microcirculation",
      "minimises photo aging",
      "increase hair volume",
      "shiny hair"
  ],
  "hand care products": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "Skin Radiance",
      "Skin Lightening",
      "Skin Microcirculation",
      "minimises photo aging",
      "increase hair volume",
      "shiny hair"
  ],
  "foot care products": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water"
  ],
  "body care products": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Relaxing",
      "Detoxifying",
      "Skin cleansing",
      "Elasticity and firmness to skin",
      "Cell renewal",
      "Skin lifting",
      "rejuvenating",
      "Anti- aging",
      "For sensitive skin",
      "skin barrier",
      "reduces redness and erythema",
      "Soothes skin irritation.",
      "Anti-pigmentation",
      "Even tone",
      "Skin brightening",
      "Skin homogeneity",
      "Cellular repair",
      "anti aging",
      "DNA repair",
      "Boost radiance",
      "reduces wrinkles and improves firmness",
      "Boosts Skin radiance",
      "restores skin firmness",
      "reduces wrinkles in 28 days",
      "Cell renewal",
      "reduces skin imperfections",
      "boosts radiance",
      "Instant Hydrating",
      "Instant Plumping",
      "Skin firming",
      "Skin repairing",
      "Skin radiance",
      "Anti - inflammatory",
      "Skin repairing",
      "Scar reduction",
      "Sebo regulating",
      "Acne/oily skin care",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "Skin Radiance",
      "Skin Lightening",
      "Skin Microcirculation",
      "minimises photo aging",
      "increase hair volume",
      "shiny hair"
  ],
  "bath / shower products": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Cell renewal",
      "reduces skin imperfections",
      "boosts radiance",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water"
  ],
  "conditioner": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity"
  ],
  "hair lotion": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity"
  ],
  "shampoo": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water"
  ],
  "soap-based": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water"
  ],
  "nail care products": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water"
  ],
  "cuticle remover / softener": [
      "Remineralising tired and stressed skin, scalp and broken nails",
      "Hydration of the skin"
  ],
  "face masks": [
      "Relaxing",
      "Detoxifying",
      "Skin cleansing",
      "Anti - inflammatory",
      "Skin repairing",
      "Scar reduction",
      "Sebo regulating",
      "Acne/oily skin care",
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength"
  ],
  "other skin care products": [
      "Relaxing",
      "Detoxifying",
      "Skin cleansing",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "other skin cleansing products": [
      "Relaxing",
      "Detoxifying",
      "Skin cleansing",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water"
  ],
  "scalp and hair roots care products": [
      "Cell renewal",
      "reduces skin imperfections",
      "boosts radiance",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity",
      "Skin Radiance",
      "Skin Lightening",
      "Skin Microcirculation",
      "minimises photo aging",
      "increase hair volume",
      "shiny hair"
  ],
  "other hair and scalp care and cleansing products": [
      "Cell renewal",
      "reduces skin imperfections",
      "boosts radiance",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity"
  ],
  "baby care products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "soap products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "toilet soap/transparent toilet soap": [
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture"
  ],
  "liquid toilet soap": [
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable"
  ],
  "baby toilet soap": [
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "baby cleansing products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water"
  ],
  "products with antiperspirant activity": [
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "other shaving and pre- / aftershaving products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity"
  ],
  "other skin products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water",
      "Skin Radiance",
      "Skin Lightening",
      "Skin Microcirculation",
      "minimises photo aging",
      "increase hair volume",
      "shiny hair"
  ],
  "antidandruff products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "synthetic-detergent based": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity"
  ],
  "lip care products": lipCareProducts,
  "lip oil": lipCareProducts,
  "lip balm": lipCareProducts,
  "lip tint": lipCareProducts,
  "make-up remover products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress"
  ],
  "makeup remover": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "shaving products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "pre- / after-shaving products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity"
  ],
  "foundation": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "concealer": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "other face make-up products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water"
  ],
  "eye shadow": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "eye pencil": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "eye liner": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "lipstick": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "other make-up products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "before and after sun products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "increases SPF and PA when mixing into the water phase",
      "Shows excellent stability over time, with minimal change in pH and viscosity",
      "excellent skin feel and superior transparency",
      "No white cast",
      "Shows high water repellency even when mixed in O/W formulations",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "anti hairloss products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "hair oil": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect"
  ],
  "hair cream": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "amino acids of proteins existing in the hair CMC (Cell Membrane Complex)",
      "Boosts hair strength and shine",
      "Enriches the dry feel of hair",
      "smoothness and silky feel",
      "Improves the feel of wet hair",
      "combability and smoothness",
      "Enhances color retention of dyed hair",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Silicone replacement",
      "Plant-derived Liquid Cuticle",
      "damaged Hair",
      "improves sensory texture of the Hair",
      "Reduces Friction of Hair & increases combability",
      "Repairs the cuticle lift-up and makes the damaged hair healthy by making it Hydrophobic.",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "oxidative hair colour products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture"
  ],
  "non oxidative hair colour/dye products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress"
  ],
  "hair bleaching and dye remover products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress"
  ],
  "other hair colouring products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable"
  ],
  "products for temporary hair styling": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity"
  ],
  "other hair styling products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water"
  ],
  "hair sun protection products": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water"
  ],
  "lip gloss": [
      "Emollient derived from L-Glutamic amino acid and phytosterol",
      "colourless viscous liquid appearance",
      "forms lamella liquid crystals such as the type III ceramides do in a model composition of the intercellular lipids in skin and in hair CMC",
      "Repairs Skin and hair damage",
      "Excellent emolliency - High water holding capacity",
      "Improves barrier function of damaged skin",
      "Gives conditioning effect to protect skin from daily stress",
      "Repairs Hair damage by dyeing/ Colouring treatments",
      "Gives conditioning effect to protect skin from daily stress",
      "Gives conditioning effect to protect hairs from daily cleansing stress"
  ],
  "sun protection products": [
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "increases SPF and PA when mixing into the water phase",
      "Shows excellent stability over time, with minimal change in pH and viscosity",
      "excellent skin feel and superior transparency",
      "No white cast",
      "Shows high water repellency even when mixed in O/W formulations",
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "other sun and self-tanning products": [
      "Anti-oxidant vitamin C with multiple intracellular anti-oxidant effects",
      "Improves Melanin suppression of B16 Melanoma cells",
      "Enhances collagen production",
      "Suppression of inflammation",
      "Improvement of Luminosity",
      "Normalization of skin generation",
      "Good Skin moisturizing & conditioning effect",
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable",
      "increases SPF and PA when mixing into the water phase",
      "Shows excellent stability over time, with minimal change in pH and viscosity",
      "excellent skin feel and superior transparency",
      "No white cast",
      "Shows high water repellency even when mixed in O/W formulations"
  ],
  "products without antiperspirant activity": [
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "- Amino acids blend developed to deeply moisture the skin. - It is available in 50% aq. Solution. - It closely mimic the NMF composition – nourish the Stratum Corneum in depth, producing a plumping and anti-wrinkle effect. - Nearly transparent, colourless and preservative-free, Applicable as paraben-free products.",
      "water-retaining",
      "Moisturizing effect",
      "Viscosity and Thickening effect",
      "lather is springy and lasts long",
      "Enables natural color control which reduces skin redness and makes skin tone lighter",
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "other hair and scalp products": [
      "Pre- biotic agent",
      "regulates skin & scalp microbiome flora",
      "96 Hr moisturization",
      "improves rough skin texture",
      "Anti- frizz (Up to 24 Hrs)",
      "anti-dandruff moisturizing & scalp microbiome efficacy",
      "Prevents acne",
      "Anti-pollutant",
      "Repairing effect on damaged hair",
      "hair and improves gloss and smoothness",
      "Strong moisturizing and enhanced skin elasticity",
      "Botanical keratin (Amino acid composition similar to natural keratin)",
      "Excellent moisturizing properties for hair care",
      "Excellent moisturizing properties for skin care",
      "Repairs hair damage",
      "Improves hair strength lost by damages",
      "Restores hair moisture and prevents dry hair",
      "Smoothens hair surface",
      "Hair Volumizing effect",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity"
  ],
  "chemical exfoliation products": [
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable"
  ],
  "mechanical exfoliation products": [
      "Mild AHA (exfoliation & skin lightening)",
      "Polyphenol extracted from rice bran",
      "Skin Moisturization & Conditioning",
      "Sebum regulator -Skin lightening",
      "Prevent discoloration",
      "pH adjuster",
      "Strong Metal chelator",
      "Deodorant",
      "Anti-oxidant",
      "Highly biodegradable"
  ],
  "other tooth care products": [
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben"
  ],
  "mouth wash": [
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben"
  ],
  "other mouth wash / breath spray products": [
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben"
  ],
  "other oral hygiene products": [
      "Silver based antimicrobial agent",
      "Antibacterial",
      "Antifungal",
      "Antiseptic",
      "Anti-viral",
      "Effective against wide range of microbes & fungi",
      "Preservatives",
      "Replacement for Paraben"
  ],
  "body or face paint": [
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "including carnival make-up": [
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "self-tanning products": [
      "Inhibition of bacterial growth",
      "Sebum quality control",
      "Increase in water content",
      "Deodorant efficacy",
      "Anti-hair fall",
      "Scalp care",
      "Anti- wrinkle"
  ],
  "skin powder/talc": [
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength"
  ],
  "mascara": [
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "other eye make-up products": [
      "Submicron rutile type",
      "Instant whiteness/Glow for all types of Skin",
      "Gives UV-A Shielding besides UV-B",
      "No patchy appearance after application",
      "Sweat/Water resistance",
      "Long lasting effect in cosmetics preparation",
      "High tinting strength",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "other lip make-up products": [
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "lipstick sealer": [
      "Highly effective Oil gelling agents",
      "Oil Gel stable at high temperature",
      "sensory feeling of oil gels same as oil",
      "Small amount of EB-21 and GP-1 (1- 4 wt %) can make hard oil gels of Cyclomethicone, Mineral oil and so",
      "Suitable for gelling Cyclomethicone, Ester oils, Fatty alcohol, Poly alcohols, Hydrocarbons & Fatty acids",
      "Transparent Hard gels & Sticks can be made by using combination of EB-21 and GP- 1 Mixture",
      "Good water-holding capability & glossiness oil paste",
      "Excellent hair conditioning ability - Non animal source of all raw material",
      "gelling agent for various oils",
      "Possible to prepare gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility",
      "Low & High polar easters, Vegetable oils,Castor oil, Mineral oil Insoluble in water",
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "hampoo": [
      "Oil soluble hydrolyzed keratin Protein",
      "Repair hair surface damage",
      "Improve hair strength",
      "Gives moist feel & makes hair manageable",
      "Improve hair texture",
      "Strong moisturizing properties",
      "enhanced skin elasticity"
  ],
  "lip stick": [
      "Pigment dispersant & binder",
      "Excellent ability for preventing sweating of lipsticks & foundations",
      "Non animal source of all raw material Solubility: Low & High polar esters, Vegetable oils, Castor oil, Mineral oil, Low viscosity silicones, Ethanol, Insoluble in Water"
  ],
  "eyebrows dye and eyelashes dye": [
      "silicone gelling agent",
      "Available as thickener for various oils",
      "Possible to prepare soft gels with thixotropic properties",
      "Stabilizer for W/O emulsion Solubility : Low & High polar easters, Vegetable oils, Castor oil, Mineral oil Insoluble in Water"
  ],
  "hair conditioner/ hair lotion": [
      "Skin Radiance",
      "Skin Lightening",
      "Skin Microcirculation",
      "minimises photo aging",
      "increase hair volume",
      "shiny hair"
  ]
}