<h4 mat-dialog-title>Terms and Conditions</h4>
<mat-dialog-content>
    <h1 class="text-center">Welcome to MAYAI</h1>
    <p class="text-center">
        Welcome to MAYAI, a B2B web platform designed to streamline cosmetic formulation development and processes,
        reducing formulation cost, time, and effort by up to 50%. Before proceeding, please read the following
        disclaimer terms carefully:
    </p>
    <h2 class="mt-3">Use of Information</h2>
    <p>
        The information provided on this platform is for professional qualified users for research and development. The
        platform assists the professional with various levels of information to ensure that their lab trials are
        effective and efficient. However, it is not intended as professional advice and should not be relied upon as
        such since it is a Gen AI platform. Users should take decisions based on their professional judgment and carry
        out their project requirements accordingly.
    </p>
    <p>
        The information provided on this platform is intended for business-to-business (B2B) purposes and is designed
        for professionals in the cosmetic industry. While we strive to ensure the accuracy and completeness of the
        information presented, we make no warranties, expressed or implied, regarding the reliability or suitability of
        the content for any particular purpose.
    </p>
    <h2>Accuracy of Information</h2>
    <p>
        While we strive to provide accurate and up-to-date information, MAYAI does not guarantee the completeness,
        reliability, or accuracy of any content, formulations, or data provided on the platform. We strive to ensure
        that the information provided on this platform is accurate and up-to-date. However, we make no warranties or
        representations regarding the accuracy, completeness, or reliability of the information.
    </p>
    <h2>Professional Advice</h2>
    <p>
        MAYAI is intended for professional use only. Users are advised to consult with qualified professionals or
        experts in cosmetic formulation regarding specific formulations, ingredients, regulatory requirements, and
        safety guidelines.
    </p>
    <h2>Third-Party Links</h2>
    <p>
        This platform may contain links to third-party websites or services that are not owned or controlled by us. We
        have no control over and assume no responsibility for the content, privacy policies, or practices of any
        third-party websites or services. The inclusion of any products or services on this platform does not imply
        endorsement by us. All product and company names mentioned herein may be the trademarks of their respective
        owners.
    </p>
    <h2>User Responsibility</h2>
    <p>
        Users of MAYAI are solely responsible for verifying the suitability and safety of any formulations or
        ingredients used based on their own research and compliance with relevant regulations and standards. Users of
        this platform are responsible for their own actions and decisions. They should exercise due diligence, caution,
        and discretion before taking any actions based on the information provided on this platform. Users are
        encouraged to independently verify all information before making decisions or taking actions based on the
        content provided on this platform. Any reliance you place on such information is strictly at your own risk. We
        disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other
        visitor to the platform or by anyone who may be informed of any of its contents.
    </p>
    <h2>Platform Functionality</h2>
    <p>
        MAYAI strives to maintain uninterrupted access and functionality. However, we do not guarantee that the platform
        will be error-free, secure, or continuously available.
    </p>
    <h2>Intellectual Property</h2>
    <p>
        All content, trademarks, logos, and intellectual property displayed on MAYAI are the property of their
        respective owners. Users agree not to reproduce, distribute, or modify any content without prior written
        permission.
    </p>
    <h2>Limitation of Liability</h2>
    <p>
        In no event shall MAYAI, its affiliates, partners, or licensors be liable for any indirect, incidental, special,
        consequential, or punitive damages arising out of or in connection with the use of the platform or reliance on
        any information provided.
    </p>
    <h2>Changes to Terms</h2>
    <p>
        We reserve the right to modify, amend, or replace these terms of use at any time. It is the user's
        responsibility to check for updates periodically.
    </p>
    <p inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="inViewportAction($event)">
        By accessing and using this platform, you agree to these terms and conditions. If you do not agree with any part
        of these terms, you must not use this platform.
    </p>
</mat-dialog-content>
<mat-dialog-actions [matTooltip]="!isFinalPage? 'Please finish reading the TNC' : ''">
    <button mat-button color="warn" mat-dialog-close [disabled]="!isFinalPage">Reject</button>
    <button mat-button color="primary" [mat-dialog-close]="true" [disabled]="!isFinalPage">Accept</button>
</mat-dialog-actions>