<div mat-dialog-content class="row" style="margin: 16px;">
    <h1 class="col-12" style="text-align: center; font-size: xxx-large; font-weight: bolder; line-height: 1;">ProductDev Edge General Terms & Conditions</h1>
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">Legal Disclaimer</h4>
    
    <p class="col-12" style="margin: 8px 16px;">productdevedge.com and the information, tools and material contained in it (productdevedge.com for use of “Maya-The illusion” tool”) are not directed to, or intended for distribution to or use by, any person or entity who is a citizen or resident of or located in any jurisdiction where such distribution, publication, availability or use would be contrary to law or regulation or which would subject Productdev Edge Private Limited (“Maya-The illusion” tool”) to any registration or licensing requirement within such jurisdiction. The information contained on (productdevedge.com) is for general information purpose. productdevedge.com is subject to periodic update and revision. Materials should only be considered current as of the date of initial publication appearing thereon, without regard to the date on which you may access the information. productdevedge.com maintains the right to delete or modify information on this Site without prior notice. </p>
    
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">LIMITED LICENSE</h4>
    
    <p class="col-12" style="margin: 8px 16px;">Subject to the terms and conditions set forth in this Agreement, Productdev Edge Private Limited will grant a non-exclusive, non-transferable, limited right to access this site and the materials thereon. You hereby agree and confirm that: </p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">Access to this site and the information contained herein is not unlawful under the applicable laws of the jurisdiction where I am resident and from where I am accessing this site. </li>
        <li class="col-12">shall not circulate copies of this information in any manner (including but not restricted to photocopying and email) of the information and data on this site and</li>
        <li class="col-12">agrees not to reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate the contents to anyone except with prior written permission from Productdev Edge Private Limited . </li>
    
    </ul>
       
    <p class="col-12" style="margin: 8px 16px;">You agree and confirm not to: </p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">Interrupt or attempt to interrupt the operation of the site/tool in any way. </li>
        <li class="col-12">Intrude or attempt to intrude into the site/tool in any way.</li>
        <li class="col-12">Post any obscene, defamatory or annoying materials on the site/dashboard.</li>
        <li class="col-12">Obscure any materials, including this notice, already posted on the site. </li>
        <li class="col-12">Use the site/tool or any contents thereof to defame, intimidate, annoy or otherwise cause nuisance or breach the rights of any person. </li>
    </ul>
     
    <p class="col-12" style="margin: 8px 8px;">Productdev Edge Private Limited authorizes to view and download the information ("Ingredient / Product Score(s) ") with the tool available on (productdevedge.com).</p>
    
    <p class="col-12" style="margin: 8px 16px;">This authorization is not a transfer of title in the Materials and copies of the Materials and is subject to the following restrictions: </p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">Retain, on all copies of the Materials downloaded, all copyright, trademarks and other proprietary notices contained in the Materials; consent.</li> 
        <li class="col-12">Not modify the Materials in any way nor reproduce or display, perform, or distribute or otherwise use them for any public purpose except  as specified in the web platform</li> 
        <li class="col-12">Not transfer the Materials to any other person unless you give them notice of, and they agree to accept, the obligations arising under these terms and conditions of use. You agree to abide by all additional restrictions displayed on the Site as it may be updated from time to time. Except as expressly provided herein, Productdev Edge Private Limited does not grant any express or implied right to you under any patents, trademarks, copyrights or trade secret information. </li> 
        <li class="col-12">The information, material or services included in or available through this site may include inaccuracies or typographical errors. Productdev Edge Private Limited assumes no responsibility on errors or omission in the contents on the Service on account of misinterpretation on the part of users of this tool . Changes are periodically made to the site/services and to the information therein. Productdev Edge Private Limited may make improvements and/or changes in the site/services at any time. Advice received via this site should not be relied upon for personal, medical, legal or financial decisions and you should consult an appropriate professional (Formulator) for specific advice tailored to your situation. This website offers safety information of ingredients/ products and is designed for educational purposes only. You should not rely on this information as a substitute for, nor does it replace, professional advice, required for your specific situation.</li> 
    </ul>
    
    
    <p class="col-12" style="margin: 8px 8px;">You specifically agree that Productdev Edge Private Limited shall not be responsible for unauthorized access to or alteration of your transmissions or data, any material or data sent or received or not sent or received, or any transactions entered into through this site. You specifically agree that Productdev Edge Private Limited is not responsible or liable for any threatening, defamatory, obscene, offensive or illegal content or conduct of any other party or any infringement of another's rights, including intellectual property rights. You specifically agree that Productdev Edge Private Limited is not responsible for any content sent using this tool and/or included in this site by any third party. </p>
    
    <p class="col-12" style="margin: 8px 8px;">In no event shall Productdev Edge Private Limited be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of this site/services, with the delay or inability to use this site/services or related services, the provision of or failure to provide services, or for any information, products, services and material obtained through this site using the tool, or otherwise arising out of the use of this site/services, whether based on contract, tort, negligence, strict liability or otherwise, even if Productdev Edge Private Limited has been advised of the possibility of damages. </p>
    
    <p class="col-12" style="margin: 8px 8px;">If you are dissatisfied with any portion of this site/services with respect to usage of the tool, or with any of these terms of use, your sole and exclusive remedy is to discontinue using this tool on the site/services. The foregoing are subject to the laws of the India and the courts in Bangalore, Karnataka, India shall have the exclusive jurisdiction on any dispute that may arise out of the use of this site. Please proceed only if you accept all the conditions enumerated herein above, out of your free will and consent.    </p>
</div>
<mat-dialog-actions *ngIf="isDialogMode">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>