import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BrandProfileService } from '../views/brand/brand-profile.service';
import { MarketplaceProfile, UserProfile } from '../app.models';
import { R } from '@angular/cdk/keycodes';
import * as forex from 'exchange-rates-api';
import { BackendService } from './backend.service';
import { CountryProviderService } from '../views/marketplace/services/country-provider.service';
import { MarketplaceProfileService } from '../views/marketplace/marketplace-profile.service';


@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private currentLocale = 'en-IN'
  private currentUserLocale = 'en-IN'
  private currentCurrency = '₹'
  private currentUserCurrency = '₹'
  private currentRegion = 'india'
  private currentUserRegion = 'india'

  constructor(
    private brandProfileService: BrandProfileService,
    private marketplaceProfileService: MarketplaceProfileService,
    private backend: BackendService,
    private countryService: CountryProviderService
  ) { 
    this.brandProfileService.asObservable().subscribe(profile => {
      if(profile){
          this.refresh(profile)
      }
    })
    this.marketplaceProfileService.subscribe(profile => {
      if(profile){
          this.refresh(profile)
      }
    })
    this.refresh()
  }

  async refresh(profile?: UserProfile | MarketplaceProfile){
    if(localStorage.getItem('region'))
      this.currentRegion = localStorage.getItem('region')
    else{
      await this.backend.getGeoDetails().then(geoDetails => {
        this.currentRegion = this.getRegionFromCountry(geoDetails["country"])
      }).catch((err) => {
      })
    }
    if(this.currentRegion){
      this.currentLocale = this.getLocaleFromRegion(this.currentRegion)
      this.currentCurrency = this.getCurrencyFromRegion(this.currentRegion)
    }
    if(profile){
      this.currentUserRegion = this.getRegionFromCountry(profile.country)
      this.currentUserLocale = this.getLocaleFromRegion(this.currentUserRegion)
      this.currentUserCurrency = this.getCurrencyFromRegion(this.currentUserRegion)
    }
  }

  private getRegionFromCountry(country: String){
    switch(country){
      case 'IN':
        return 'india'
      case "US":
        return 'us'
      case "VN":
        return 'india'
      case "TH":
        return 'india'
      case "GB":
        return 'uk'
      default: 
        return 'europe'
    }
  }

  private getCurrencyFromRegion(region: string){
    switch(region){
      case 'europe':
        return '€'
      case 'uk':
        return '₤'
      case 'us':
        return '$'
      case 'india':
      default:
        return '₹'
    }
  }

  getCurrencyCode(){
    switch(this.currentCurrency){
      case '€':
        return 'EUR'
      case '₤':
        return 'GBP'
      case '$':
        return 'USD'
      case '₹':
      default:
        return 'INR'
    }
  }

  private getLocaleFromRegion(region: string){
    switch(region){
      case 'europe':
      case 'uk':
        return 'en-GB'
      case 'us':
        return 'en-US'
      case 'india':
      default:
        return 'en-IN'
    }
  }


  private getLocaleFromCurrency(region: string){
    switch(region){
      case '€':
      case '₤':
        return 'en-GB'
      case '$':
        return 'en-US'
      case '₹':
      default:
        return 'en-IN'
    }
  }

  getUserCurrencyCode(){
    switch(this.currentUserCurrency){
      case '€':
        return 'EUR'
      case '₤':
        return 'GBP'
      case '$':
        return 'USD'
      case '₹':
      default:
        return 'INR'
    }
  }

  getLocale(){
    return this.currentLocale
  }

  getUserLocale(){
    return this.currentUserLocale
  }

  getCurrency(){
    return this.currentCurrency
  }

  getUserCurrency(){
    return this.currentUserCurrency
  }

  convertAmountToCurrency(amount: number | string, fromCurrency: string, toCurrency: string){
    if(typeof(amount)=='string')
      amount = Number(amount)
    if(amount == 0)
      return 0
    if(fromCurrency == toCurrency)
      return amount
    let conversionRate = 1
    let inrAmount = amount
    switch(fromCurrency){
      case 'GBP':
        inrAmount = amount*108
        break
      case 'EUR':
        inrAmount = amount*92
        break
      case 'USD':
        inrAmount = amount*84
        break
    }
    switch(toCurrency){
      case 'GBP':
        conversionRate = 108
        break
      case 'EUR':
        conversionRate = 92
        break
      case 'USD':
        conversionRate = 84
        break
      case 'INR':
        conversionRate = 1
    }
    let divisor = 100
    if(inrAmount<=10000)
      divisor = 1
    else if(inrAmount <= 100000)
      divisor = 10
    return Math.ceil((inrAmount/conversionRate)/divisor)*divisor
  }

  getAmountInLocalCurrency(amount: number | string, userCurrency: boolean = false){
    let toForexCurrency = "INR"
    if(typeof(amount)=='string')
      amount = Number(amount)
    let currency = this.getCurrency()
    if(userCurrency)
      currency = this.getUserCurrency()
    let divisor = 100
    if(amount<=10000)
      divisor = 1
    else if(amount <= 100000)
      divisor = 10
    if(amount == 0)
      return '0'
    switch(currency){
      case '€':
        toForexCurrency = 'EUR';
        return (Math.ceil((amount / 92)/divisor)*divisor).toLocaleString(userCurrency? this.getUserLocale() : this.getLocale())
        break;
      case '₤':
        toForexCurrency = 'GBP';
        return (Math.ceil((amount / 108)/divisor)*divisor).toLocaleString(userCurrency? this.getUserLocale() : this.getLocale())
        break;
      case '$':
        toForexCurrency = 'USD';
        return (Math.ceil((amount / 84)/divisor)*divisor).toLocaleString(userCurrency? this.getUserLocale() : this.getLocale())
        break;
      case '₹':
      default:
        toForexCurrency = 'INR';
        return (Math.ceil((amount / 1)/divisor)*divisor).toLocaleString(userCurrency? this.getUserLocale() : this.getLocale())
        break;
    }
    // return forex.convert(amount, "INR", toForexCurrency, 'latest')
  }

  getCountryCodeFromRegion(): any {
    switch(this.currentRegion){
        case 'europe':
          return "FR"
        case 'uk':
          return 'GB'
        case 'us':
          return 'US'
        case 'india':
        default:
          return 'IN'
    }
  }
  
  getUserRegion(): string {
    switch(this.currentUserRegion){
      case 'us':
        return 'US'
      case 'uk':
      case 'europe':
        return 'Europe'
      case 'india':
      default:
        return 'India'
    }
  }

  getRegion(): string {
    return this.currentRegion
  }

}
